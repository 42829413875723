<template>
	<div class="d-flex flex-column max-height-100">
		<!-- Cabecera -->

		<!-- Info y mapa -->
		<div class="d-flex flex-grow-1 fill-height" :style="{ height: `100vh` }">
			<div v-if="!isSmallScreen" :style="{ width: 'max(' + dinamicWidth + 'px, ' + dinamicWidthPercentage + 'vw)' }">
				<map-panel-info-periods
					@isHidePanel="changeHidePanel"
					:currentmap="currentMap"
					:idStopSelected="idStopSelected"
					:selectAction="selectAction"
				/>
			</div>
			<div v-else :style="{ width: 'max(' + dinamicWidth + 'px, ' + dinamicWidthPercentage + 'vw)' }">
				<map-panel-info-periods
					@isHidePanel="changeHidePanel"
					:currentmap="currentMap"
					:idStopSelected="idStopSelected"
					:selectAction="selectAction"
				/>
			</div>

			<div class="flex-grow-1">
				<map-panel-map-periods :currentmap="currentMap" :idStopSelected="idStopSelected" />
			</div>
		</div>
	</div>
</template>

<script>
import MapPanelInfoPeriods from '@/components/operations/map/sections/infoperiods/MapPanelInfoPeriods';
import MapPanelMapPeriods from '@/components/operations/map/sections/map/MapPanelMapPeriods';

/** Constants */
import { MAP_PERIODS_ID } from '@/components/operations/map/sections/map/subcomponents/mapVariables';

/** Mixins */
import StopsServiceMixin from '@/mixins/StopsServiceMixin';
import CartoMixin from '@/mixins/CartoMixin.js';
import ColorsAuthorityMixin from '@/mixins/ColorsAuthorityMixin.js';

export default {
	name: 'operationsmapperiods',

	mixins: [StopsServiceMixin, CartoMixin, ColorsAuthorityMixin],

	components: {
		MapPanelInfoPeriods,
		MapPanelMapPeriods
	},

	data() {
		return {
			//instantStops: []
			isSmallScreen: false,
			idStopSelected: '',
			selectAction: '',
			dinamicWidth: 350,
			dinamicWidthPercentage: 25
		};
	},

	props: {
		currentMap: {
			type: String,
			required: false,
			default: MAP_PERIODS_ID
		}
	},

	beforeDestroy() {
		this.$store.commit('setContainerHeaderVisibility', true);
	},

	beforeRouteEnter(to, from, next) {
		if (!to.fullPath.includes('planningselect') && !to.fullPath.includes('authorizeselect')) {
			next((vm) => {
				vm.idStopSelected = '';
				vm.selectAction = '';
			});
		}
		next();
	},

	destroyed() {},

	mounted() {
		this.$store.commit('setContainerHeaderVisibility', false);
		this.setStatusColorsAutority();
	},

	created() {
		// Responsive map
		window.addEventListener('resize', this.checkScreenSize);
		this.checkScreenSize();
		this.changeHidePanel(false);
		//

		if (this.$attrs.method == 'planningselect' || this.$attrs.method == 'authorizeselect') {
			this.idStopSelected = this.$attrs.idstop;
			this.selectAction = this.$attrs.method;
		} else {
			this.idStopSelected = '';
			this.selectAction = '';
		}

		if (this.$store.getters.stopsStatusValues == null) {
			this.setStatusColorsAutority();
		}

		if (this.$store.getters.stopsTypeValues == null) {
			this.setTypeColorsAutority();
		}

		if (this.$store.getters.defaultBackgroundColor == null) {
			this.setDefaultBackgroundColor();
		}
		if (this.$store.getters.defaultStrokeColor == null) {
			this.setDefaultStrokeColor();
		}
	},

	computed: {},

	methods: {
		checkScreenSize() {
			this.isSmallScreen = window.innerWidth <= 600;
		},
		/**
		 * Cambiar el tamaño del panel
		 */
		changeHidePanel(valor) {
			if (valor) {
				if (this.isSmallScreen) {
					this.dinamicWidth = 20;
					this.dinamicWidthPercentage = 1;
				} else {
					this.dinamicWidth = 42;
					this.dinamicWidthPercentage = 0;
				}
			} else {
				if (this.isSmallScreen) {
					this.dinamicWidth = 0;
					this.dinamicWidthPercentage = 100;
				} else {
					this.dinamicWidth = 350;
					this.dinamicWidthPercentage = 25;
				}
			}
		}
	},

	watch: {}
};
</script>

<style lang="postcss">
@import '../styles/app-variables.pcss';
.mappanel {
	&__header {
		border-bottom: 1px solid var(--moderate);
	}
	&--fill-height {
		height: calc(100vh - 52px);
		max-height: calc(100vh - 52px);
	}
}
</style>
