export default {
	computed: {
		statusColorsOpts() {
			const opts = {
				model: 'stopstatuspa',
				page: 1,
				rows: null,
				filter: {
					groupOp: 'and',
					groups: [],
					rules: [{ field: 'visible_maps', op: 'eq', data: true }]
				}
			};
			return opts;
		},

		typeColorsOpts() {
			const opts = {
				model: 'shiptypepa',
				page: 1,
				rows: null
			};
			return opts;
		}
	},

	methods: {
		/**
		 * Get Status colors
		 */
		async setStatusColorsAutority() {
			var opts = this.statusColorsOpts;
			var haveStatusPl = false;
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				const statusColors = response.data.data.reduce((json, data) => {
					// Utilizamos el valor de la propiedad 'visitstatuscode' como clave del JSON
					const key = data.stopstatuscode;
					if (key == 'PL') {
						haveStatusPl = true;
					}
					// Asignamos el objeto actual al JSON con la clave correspondiente
					json[key] = {
						color: data.colorhex,
						coloroutline: data.colorhexoutline,
						text: data.stopstatusname.toUpperCase(),
						shape: 'point',
						visiblemaps: data.visiblemaps,
						visibleberthing: data.visibleberthingplan
					};
					// Retornamos el JSON actualizado
					return json;
				}, {});

				// Update in state if port autority have PL status
				this.$store.commit('setPortAutorityHaveStatusPl', haveStatusPl);

				// Update in state statusColors
				this.$store.commit('setStopsStatusValues', statusColors);
			});
		},

		/**
		 * Get type ship colors
		 */
		async setTypeColorsAutority() {
			var opts = this.typeColorsOpts;
			this.$puiRequests.postRequest('/puisearch', opts, (response) => {
				const typeColors = response.data.data.reduce((json, data) => {
					// Utilizamos el valor de la propiedad 'visitstatuscode' como clave del JSON
					const key = data.code;
					// Asignamos el objeto actual al JSON con la clave correspondiente
					json[key] = {
						color: data.colorhex,
						coloroutline: data.colorhexoutline,
						text: data.description.toUpperCase(),
						shape: 'point'
					};
					// Retornamos el JSON actualizado
					return json;
				}, {});

				// Update in state
				this.$store.commit('setStopsTypeValues', typeColors);
			});
		},
		/**
		 * Get ship AIS default background color
		 */
		setDefaultBackgroundColor() {
			const puiVariableUrl = '/puivariable/getVariable/SHIP_TYPE_DEFAULT_BACKGROUND_COLOR';
			this.$puiRequests.getRequest(
				puiVariableUrl,
				null,
				(response) => {
					if (response.data) {
						// Update in STATE
						this.$store.state.mapconfig.defaultBackgroundColor = response.data;
					}
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
		},
		/**
		 * Get ship AIS default stroke color
		 */
		setDefaultStrokeColor() {
			const puiVariableUrl = '/puivariable/getVariable/SHIP_TYPE_DEFAULT_FOREGROUND_COLOR';
			this.$puiRequests.getRequest(
				puiVariableUrl,
				null,
				(response) => {
					if (response.data) {
						// Update in STATE
						this.$store.state.mapconfig.defaultStrokeColor = response.data;
					}
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				}
			);
		}
	}
};
