<template>
	<div class="map-container" ref="map">
		<map-update-control />
		<map-popup :stop="featureHover"></map-popup>
		<map-zooms-control :currentmap="currentmap" />
		<map-legend-control :currentmap="currentmap" />
		<map-base-layer-control />
	</div>
</template>

<script>
/** Components */
import MapZoomsControl from '@/components/operations/map/sections/map/subcomponents/MapZoomsControlMap';
import MapPopup from './subcomponents/MapPopup';
import MapLegendControl from './subcomponents/MapLegendBody';
import MapBaseLayerControl from './subcomponents/MapBaseLayerControl';
import MapUpdateControl from './subcomponents/MapUpdateControl';

/** Constants */
import { TYPE_VIS, CENTER, INITIAL_ZOOM, MIN_ZOOM_BOLLARDS_BERTHS } from './subcomponents/mapVariables';
import ol2map from './subcomponents/ol2map';
import { STOP_INSTANT_LAYER_ID, BERTHS_LAYER_ID, BOLLARDS_LAYER_ID, SELECT_STOP_LAYER_ID } from '@/components/operations/map/constants/layers';
import { PLANNING_PANEL_ID, REQUESTEDS_PANEL_ID } from '@/lib/variables/panels';
import {
	FEC_TODAY,
	FEC_PAST_INI,
	FEC_PAST_END,
	FEC_FUTURE_END,
	FEC_REQPL_INI
} from '@/components/operations/map/sections/infoperiods/InitialInstantDates';

/** Utils */
import { getPanelOptions } from '@/lib/utils/features';
import mapUtils from '../../mapUtils';
import mapFeatureStyleUtils from '../../mapFeatureStyleUtils';

/** Creators */
import StopsFeatureCreator from '@/components/operations/map/creators/StopsFeatureCreator';
import AISTrackEventPointFeatureCreator from '@/components/operations/map/creators/AISTrackEventPointFeatureCreator';
import AISTrackEventVesselLineFeatureCreator from '@/components/operations/map/creators/AISTrackEventVesselLineFeatureCreator';
import MovementSequenceFeatureCreator from '@/components/operations/map/creators/MovementSequenceFeatureCreator';

/** Styles */
import bollardStyle from '@/components/operations/map/styles/bollardStyle';
import berthStyle from '@/components/operations/map/styles/berthStyle';

/** Mixins */
import BaseLayersMixin from '@/mixins/BaseLayersMixin';
import StopsServiceMixin from '@/mixins/StopsServiceMixin';
import TrackServiceMixin from '@/mixins/TrackServiceMixin';
import MovementSequenceMixin from '@/mixins/MovementSequenceMixin';

/** 3rd-party */
import 'ol/ol.css';
import View from 'ol/View';
import Overlay from 'ol/Overlay';
import Attribution from 'ol/control/Attribution';
import { Polygon } from 'ol/geom';

const attribution = new Attribution({
	collapsible: false
});

const optsBollardsBerthsLayer = {
	minZoom: MIN_ZOOM_BOLLARDS_BERTHS
};

export default {
	name: 'MapPanelMapPeriods',

	mixins: [BaseLayersMixin, StopsServiceMixin, TrackServiceMixin, MovementSequenceMixin],

	components: {
		MapPopup,
		MapZoomsControl,
		MapLegendControl,
		MapBaseLayerControl,
		MapUpdateControl
	},

	props: {
		currentmap: {
			type: String,
			required: true
		},

		idStopSelected: {
			type: String,
			required: false
		}
	},

	data() {
		return {
			stopSelectLayer: null,
			portInfo: null,
			portExtensions: null,
			featureHover: null,
			isBerthsAddedLayer: false,
			isBollardsAddedLayer: false,
			updatePanel: false
		};
	},

	created() {
		this.stopsFeatureCreator = new StopsFeatureCreator(
			(stopsCreated) => this.onStopsCreated(stopsCreated),
			this.$store.getters.getMapPeriodsStyleVisualization,
			4
		);
		this.aisTrackEventPointFeatureCreator = new AISTrackEventPointFeatureCreator();
		this.aisTrackEventVesselLineFeatureCreator = new AISTrackEventVesselLineFeatureCreator();
		this.movementSequenceFeatureCreator = new MovementSequenceFeatureCreator();
		this.setStatusAndViewCodes();
	},

	mounted() {
		this.$puiEvents.$on('stopfeaturecreator:updated', this.processStopsInstantLayer);
		// Get extension
		const mapAutorityExtent = this.$store.getters.mapAutorityExtent;
		if (mapAutorityExtent !== null) {
			this.createMap(mapAutorityExtent);
		} else {
			this.getExtensions();
		}
	},

	beforeDestroy() {},

	destroyed() {
		this.stopsFeatureCreator.terminateWorkers();
		this.$store.commit('setMapPeriodsLoaded', false);
		this.$puiEvents.$off('stopfeaturecreator:updated', this.processStopsInstantLayer);
		this.$puiEvents.$off('map:updated', this.setInstantStops);

		// Planning clean
		this.$store.commit('setStopsFeaturesInstantPL', []);
		this.$store.commit('setStopsFeaturesInstantModifiedPL', []);
		this.$store.commit('setStopsFeaturesInstantWithModifiedPL', []);
		this.$store.commit('setStopsFeaturesInstantRemovedPL', []);

		// Authorization clean
		this.$store.commit('setStopsFeaturesInstantAU', []);
		this.$store.commit('setStopsFeaturesInstantModifiedAU', []);
		this.$store.commit('setStopsFeaturesInstantWithModifiedAU', []);
		this.$store.commit('setStopsFeaturesInstantRemovedAU', []);

		// Limpiamos las pestañas abiertas
		this.$store.commit('cleanTabs');

		// Instant planning dates
		this.$store.commit('setFecIniPastpanel', FEC_PAST_INI);
		this.$store.commit('setFecEndPastpanel', FEC_PAST_END);
		this.$store.commit('setFecIniFuturepanel', FEC_TODAY);
		this.$store.commit('setFecEndFuturepanel', FEC_FUTURE_END);
		this.$store.commit('setFecIniRequestedspanel', FEC_TODAY);
		this.$store.commit('setFecEndRequestedspanel', FEC_REQPL_INI);
		this.$store.commit('setFecIniPlanningpanel', FEC_TODAY);
		this.$store.commit('setFecEndPlanningpanel', FEC_REQPL_INI);
	},

	computed: {
		dateChanged() {
			return [
				this.$store.state.mappanelperiods.fecIniPastpanel,
				this.$store.state.mappanelperiods.fecEndPastpanel,
				this.$store.state.mappanelperiods.fecIniFuturepanel,
				this.$store.state.mappanelperiods.fecEndFuturepanel
			];
		},
		dateChangedPlanning() {
			return [this.$store.state.mappanelperiods.fecIniPlanningpanel, this.$store.state.mappanelperiods.fecEndPlanningpanel];
		},
		dateChangedAuthorization() {
			return [this.$store.state.mappanelperiods.fecIniRequestedspanel, this.$store.state.mappanelperiods.fecEndRequestedspanel];
		},
		activePanel() {
			return this.$store.getters.activePanelPeriods;
		},
		activePanelOptionsPeriods() {
			return this.$store.getters.activePanelOptionsPeriods;
		},
		isPlanningPanelId() {
			const activePanelInstant =
				this.activePanelOptionsPeriods && this.activePanelOptionsPeriods.panelId.includes('::')
					? this.activePanelOptionsPeriods.instantPanel
					: this.activePanel;
			if (activePanelInstant == PLANNING_PANEL_ID) {
				return true;
			}
			return false;
		},
		isRequestedsPanelId() {
			const activePanelInstant =
				this.activePanelOptionsPeriods && this.activePanelOptionsPeriods.panelId.includes('::')
					? this.activePanelOptionsPeriods.instantPanel
					: this.activePanel;
			if (activePanelInstant == REQUESTEDS_PANEL_ID) {
				return true;
			}
			return false;
		},
		dynamicPanels() {
			return this.$store.getters.dynamicPanelsPeriods;
		},

		berths() {
			return this.$store.getters.berths;
		},

		bollards() {
			return this.$store.getters.bollards;
		},

		pastStatusCode() {
			return this.$store.getters.getPastStatusCode;
		},

		futureStatusCode() {
			return this.$store.getters.getFutureStatusCode;
		},

		requestedsStatusCode() {
			return this.$store.getters.getRequestedsStatusCode;
		},

		planningStatusCode() {
			return this.$store.getters.getPlanningStatusCode;
		},

		ports() {
			return this.$store.state.session.properties.PORTS.filter(
				(port) => port.portauthorityid == window.localStorage.getItem('workingPortAuthorityId')
			);
		}
	},

	methods: {
		/**
		 * Stops
		 */
		setInstantStopsOption() {
			if (this.idStopSelected != '') {
				this.$puiEvents.$emit('map:stopprogress', true);
				// Get the data by the id selected
				const jsonString = JSON.parse(atob(this.idStopSelected));
				this.setInstantStopsBySelected(jsonString.fecini, jsonString.fecend, jsonString.id);
				this.stopsFeatureCreator.setIdStopSelected(jsonString.id);
			} else {
				this.stopsFeatureCreator.setIdStopSelected(null);
				// Get the data
				this.setInstantStops();
			}
		},
		async setInstantStops(getModifiedValues = false) {
			this.$store.commit('setProcessVesselsRunning', true);
			this.stopsFeatureCreator.setIdStopSelected(null);
			this.$puiEvents.$emit('map:refreshprogress');
			var opts = this.getStopInstantOpts(this.activePanel, this.activePanelOptionsPeriods);

			if (getModifiedValues) {
				if (this.isPlanningPanelId) {
					this.instantStops = this.$store.getters.getStopsFeaturesInstantWithModifiedPL;
				} else {
					this.instantStops = this.$store.getters.getStopsFeaturesInstantWithModifiedAU;
				}
			} else {
				this.instantStops = await this.getStops(opts, STOP_INSTANT_LAYER_ID);
				// Guardamos los stops iniciales
				this.$store.commit('setStopsFeaturesInstantInitially', this.instantStops);
			}

			if (this.instantStops.length > 0) {
				this.processStopsInstantLayer(this.instantStops);
			} else {
				this.processStopsInstantLayer([]);
				if (this.isPlanningPanelId) {
					this.$store.commit('setStopsFeaturesInstantPL', []);
				} else if (this.isRequestedsPanelId) {
					this.$store.commit('setStopsFeaturesInstantAU', []);
				} else {
					this.$store.commit('setStopsFeaturesInstant', []);
				}
			}
		},

		async setInstantStopsBySelected(fecini, fecend, idstop) {
			this.$puiEvents.$emit('map:refreshprogress');
			var opts = this.getStopsInstantRequestedOptsSelected(fecini, fecend, idstop);

			this.instantStops = await this.getStops(opts, STOP_INSTANT_LAYER_ID);

			if (this.instantStops.length > 0) {
				this.processStopsInstantLayer(this.instantStops);
			} else {
				if (this.isPlanningPanelId) {
					this.$store.commit('setStopsFeaturesInstantPL', []);
				} else if (this.isRequestedsPanelId) {
					this.$store.commit('setStopsFeaturesInstantAU', []);
				} else {
					this.$store.commit('setStopsFeaturesInstant', []);
				}
			}
		},

		setStatusAndViewCodes() {
			const statusCodes = this.getStatusCodes(this.activePanel, this.activePanelOptionsPeriods);
			const viewCodes = this.getViewCodes(this.activePanel, this.activePanelOptionsPeriods);
			const searchtext = this.getSearchTextPanel(this.activePanel, this.activePanelOptionsPeriods);
			this.stopsFeatureCreator.setStatusCodes(statusCodes);
			this.stopsFeatureCreator.setViewCodes(viewCodes);
			this.stopsFeatureCreator.setSearchingTexVesselName(searchtext);
		},
		createMap(maxExtent) {
			this.$nextTick(() => {
				this.resolveMap(maxExtent);
			});
			this.$nextTick(() => {
				this.registerMapEvents();
			});
			this.$nextTick(this.setupMap);
		},
		resolveMap(maxExtent) {
			var self = this;
			const mapCanvas = this.$refs.map;

			this.overlay = new Overlay({
				element: document.getElementById('map-popup'),
				autoPan: false, // True: Ajustar al contenido para que se vea el popup
				autoPanAnimation: {
					duration: 250
				}
			});

			ol2map.init({
				// the map will be created using the 'map' ref
				controls: [attribution],
				target: mapCanvas,
				layers: [
					this.initialBaseLayerMap,
					ol2map.createVectorLayerFeatures(BERTHS_LAYER_ID, [], berthStyle, optsBollardsBerthsLayer),
					ol2map.createVectorLayerFeatures(BOLLARDS_LAYER_ID, [], bollardStyle, optsBollardsBerthsLayer),
					this.stopsFeatureCreator.getInstantLayer(),
					// Track event Point layer
					this.aisTrackEventPointFeatureCreator.getTrackEventPointLayer(),
					// Track event Line vessel layer
					this.aisTrackEventVesselLineFeatureCreator.getTrackEventVesselLineLayer(),
					this.aisTrackEventVesselLineFeatureCreator.getTrackEventVesselLinePointLayer(), // Esta es para pintar los arrow events
					// Movement sequence layer
					this.movementSequenceFeatureCreator.getMovementSequenceLayer(),
					// SelectLayer
					this.stopsFeatureCreator.getStopSelectLayer()
				],
				overlays: [this.overlay],
				// the map view will initially show the whole world
				view: new View({
					zoom: INITIAL_ZOOM,
					center: CENTER,
					extent: maxExtent
				})
			});

			// Go to port if fit extent exist
			const fitExtent = this.$store.getters.getMapPeriodsFitPortExtent;
			console.log('fitExtent');
			console.log(fitExtent);
			if (fitExtent) {
				ol2map.zoomToExtent(fitExtent);
			}
		},

		openPanel(identifier, data) {
			const activePanelInstant =
				this.activePanelOptionsPeriods && this.activePanelOptionsPeriods.panelId.includes('::')
					? this.activePanelOptionsPeriods.instantPanel
					: this.activePanel;
			const options = getPanelOptions(identifier, data, activePanelInstant);

			if (options.panelId == this.activePanel) {
				return;
			}

			this.$store.commit('addDynamicPanelPeriods', options);
			this.$store.commit('setActivePanelPeriods', options);
		},

		/**
		 * Fired when panel changes
		 */
		onActivePanelChange(id) {
			const options = this.dynamicPanels.find(({ panelId }) => panelId === id) || {};
			this.setStatusAndViewCodes();

			// Segun el panel activamos o desactivamos el progress bar
			const activePanelProgresBar = this.getProgressPanelActive(this.activePanel, this.activePanelOptionsPeriods);
			const eventName = activePanelProgresBar ? 'map:startprogress' : 'map:stopprogress';
			this.$puiEvents.$emit(eventName, activePanelProgresBar);

			// Si tenemos paneles dinamicos abiertos no hemos de volver a procesar. Estamos trabajando en alguno de los instantes
			if (this.idStopSelected || this.$store.getters.dynamicPanelsPeriods.length > 0) {
				// NO hagas nada, no hemos de refrescar solo hay una pestaña
				this.centerMapStop(options);
				return;
			} else if (!activePanelProgresBar && this.isPlanningPanelId && this.$store.getters.getStopsFeaturesInstantModifiedPL.length > 0) {
				this.$store.state.mappanelperiods.mapPeriodsIsLoaded && this.setInstantStops(true);
			} else if (!activePanelProgresBar && !this.isPlanningPanelId && this.$store.getters.getStopsFeaturesInstantModifiedAU.length > 0) {
				this.$store.state.mappanelperiods.mapPeriodsIsLoaded && this.setInstantStops(true);
			} else {
				this.$store.state.mappanelperiods.mapPeriodsIsLoaded && this.setInstantStops();
			}
			this.centerMapStop(options);
		},

		centerMapStop(options) {
			if (options.coordinates) {
				this.centerMap(options.coordinates);
				this.selectStop(options);
			} else {
				this.stopsFeatureCreator.clearSelectStops();
			}
		},

		closeDynamicTabsPanel() {
			if (this.dynamicPanels.length > 0) {
				const dynamicTabsToMantain = this.dynamicPanels.filter((obj) => {
					return obj.instantPanel != this.activePanel;
				});
				this.$store.commit('setDynamicPanelPeriods', dynamicTabsToMantain);
			}
		},

		centerMap(coordinates) {
			const duration = 300;
			const zoom = 17;
			ol2map
				.getMapInstance()
				.getView()
				.animate({
					center: [coordinates[0], coordinates[1]],
					duration,
					zoom
				});
		},

		selectStop(options) {
			this.stopsFeatureCreator.clearSelectStops();
			this.stopsFeatureCreator.addFeatureSelectStop(options.coordinates, options.data.vessellength);
		},

		/**
		 * Setup map & layers
		 */
		setupMap() {
			// Add berts and bollards layers
			this.addBerthsLayer();
			this.addBollardsLayer();
			if (this.idStopSelected != '') {
				this.setInstantStopsOption();
			} else {
				this.setInstantStops();
			}
		},

		addBerthsLayer() {
			const berthsFeatures = this.stopsFeatureCreator.processBerths(this.berths);
			this.stopsFeatureCreator.setBerthLayer(ol2map.getLayerById(BERTHS_LAYER_ID));
			ol2map.addFeaturesToVectorLayer(BERTHS_LAYER_ID, berthsFeatures);
			this.isBerthsAddedLayer = true;
		},

		addBollardsLayer() {
			const bollardFeatures = this.stopsFeatureCreator.processBollards(this.bollards);
			this.stopsFeatureCreator.setBollardLayer(ol2map.getLayerById(BOLLARDS_LAYER_ID));
			ol2map.addFeaturesToVectorLayer(BOLLARDS_LAYER_ID, bollardFeatures);
			this.isBollardsAddedLayer = true;
		},

		processStops(stops) {
			this.$store.commit('setProcessVesselsRunning', true);
			// Ordenamos los barcos antes de procesarlos
			stops = this.orderStopsBeforeProcess(stops);
			this.stopsFeatureCreator.processStops(stops);
		},

		processStopsInstantLayer(stops, updatePanel = false) {
			this.updatePanel = updatePanel;
			this.stopsFeatureCreator.clearStops(STOP_INSTANT_LAYER_ID);
			this.clearBerthBoatsMap();
			stops.length && this.isBerthsAddedLayer && this.isBollardsAddedLayer && this.processStops(stops);
			if (stops.length == 0) {
				if (this.isPlanningPanelId) {
					var modified = this.$store.getters.getFeaturesInstantRemovedPL.find((stop) => {
						return stop.modified === true;
					});
					if (modified) {
						this.$store.commit('setStopsFeaturesInstantWithModifiedPL', []);
					} else {
						this.$store.commit('setStopsFeaturesInstantPL', []);
					}
				} else if (this.isRequestedsPanelId) {
					var modified = this.$store.getters.getFeaturesInstantRemovedAU.find((stop) => {
						return stop.modified === true;
					});
					if (modified) {
						this.$store.commit('setStopsFeaturesInstantWithModifiedAU', []);
					} else {
						this.$store.commit('setStopsFeaturesInstantAU', []);
					}
				} else {
					this.$store.commit('setStopsFeaturesInstant', []);
				}
			}
			// Ponemos a false el proceso de running
			this.$store.commit('setProcessVesselsRunning', false);
		},

		onStopsCreated() {
			var self = this;
			//this.$puiNotify.info(`${this.$t('operations.stops_created')}`);
			console.log('Barcos procesados...');

			// Update layers Features data
			const instantFeatures = ol2map.getFeaturesLayerById(STOP_INSTANT_LAYER_ID).map((feature) => {
				return feature.getProperties();
			});

			const cleanFeatures = this.cleanStopsToProcess(instantFeatures);

			// Update dymanic panels if exist
			const instantFeaturesModified = instantFeatures.filter((feature) => feature.modified);
			if (this.dynamicPanels.length > 0 && instantFeaturesModified.length > 0) {
				instantFeaturesModified.forEach(function (feat) {
					const activePanelInstant =
						self.activePanelOptionsPeriods && self.activePanelOptionsPeriods.panelId.includes('::')
							? self.activePanelOptionsPeriods.instantPanel
							: self.activePanel;
					const options = getPanelOptions(feat.id, feat, activePanelInstant);
					self.dynamicPanels.filter((obj) => {
						if (self.updatePanel && obj.panelId == options.panelId) {
							// Update dynamic panel
							self.$store.commit('updateDynamicTabData', options);
							if (self.activePanelOptionsPeriods && obj.panelId == self.activePanelOptionsPeriods.panelId) {
								//Update overlayer select
								if (options.coordinates) {
									//self.centerMap(options.coordinates);
									self.selectStop(options);
								}
							}
						}
					});
				});
			}

			// Existen modificados
			var modified = this.$store.getters.getFeaturesInstantRemovedAU.find((stop) => {
				return stop.modified === true;
			});

			if (instantFeaturesModified.length > 0 || modified) {
				if (this.isPlanningPanelId) {
					this.$store.commit('setStopsFeaturesInstantWithModifiedPL', cleanFeatures);
				} else {
					this.$store.commit('setStopsFeaturesInstantWithModifiedAU', cleanFeatures);
				}
			}

			if (this.isPlanningPanelId) {
				this.$store.commit('setStopsFeaturesInstantPL', cleanFeatures);
			} else if (this.isRequestedsPanelId) {
				this.$store.commit('setStopsFeaturesInstantAU', cleanFeatures);
			} else {
				this.$store.commit('setStopsFeaturesInstant', cleanFeatures);
			}
			// Ponemos la capa en visible
			ol2map.setVisibility(STOP_INSTANT_LAYER_ID, true);
			this.$store.commit('setProcessVesselsRunning', false);
		},

		clearBerthBoatsMap() {
			this.stopsFeatureCreator && this.stopsFeatureCreator.clearBerthBoatsMap();
		},

		/**
		 * Extension
		 */
		getExtensions() {
			const workingPortsId = window.localStorage.getItem('workingPortsId');

			if (workingPortsId) {
				const opts = {
					model: 'port',
					filter: {
						groupOp: 'and',
						groups: [],
						rules: [
							{
								field: 'id',
								op: 'in',
								data: workingPortsId
							}
						]
					}
				};
				this.$puiRequests.postRequest('/puisearch', opts, (response) => {
					this.portInfo = response.data.data[0];
					this.portExtensions = response.data.data.map((objeto) => {
						const { id, portlocode, portauthorityname, bbox } = objeto;
						return { id, portlocode, portauthorityname, bbox };
					});
					this.$store.commit('setMapPortsExtents', this.portExtensions);
				});
			}
		},

		changeFeaturesStyle(resolution) {
			var self = this;
			ol2map.getLayerById(STOP_INSTANT_LAYER_ID) &&
				ol2map.getLayerById(STOP_INSTANT_LAYER_ID).getSource() &&
				ol2map
					.getLayerById(STOP_INSTANT_LAYER_ID)
					.getSource()
					.forEachFeature(function (feature) {
						mapFeatureStyleUtils.setStyleFeatureVesselBbdd(
							feature,
							resolution,
							new Polygon([feature.getProperties().polyBounds]),
							feature.getProperties().puntoAtraque.x,
							feature.getProperties().puntoAtraque.y,
							self.$store.getters.getMapPeriodsStyleVisualization
						);
						feature.changed();
					});
		},

		/**
		 * Map events
		 */
		registerMapEvents() {
			var self = this;
			const mapCanvas = this.$refs.map;

			ol2map.getMapInstance().once('rendercomplete', function () {
				self.$store.commit('setMapPeriodsLoaded', true);
			});

			ol2map.getMapInstance().on('moveend', function () {
				self.$store.commit('setMapZoomPeriods', ol2map.getZoom());
				self.$store.commit('setMapBbox', ol2map.getMapExtent());
			});

			ol2map.getMapInstance().on(
				'pointermove',
				(evt) => {
					const hit = ol2map.getMapInstance().forEachFeatureAtPixel(evt.pixel, (feature, layer) => {
						if (layer && layer.get('id') === SELECT_STOP_LAYER_ID) {
							return undefined;
						}
						return feature;
					});
					if (hit) {
						if (!this.featureHover) {
							this.featureHover = hit.getProperties();
							// Solo pintamos un popup si se trata de un barco
							if (
								(this.featureHover.vesselimo ||
									this.featureHover.isAis ||
									this.featureHover.isEventWaypoint ||
									this.featureHover.isEventLine ||
									this.featureHover.isMovementWaypoint) &&
								this.featureHover.featureVisible
							) {
								if (this.featureHover.clickable) {
									mapCanvas.style.cursor = 'pointer';
								} else {
									mapCanvas.style.cursor = '';
								}
								this.overlay.setPosition(evt.coordinate);
							}
						}
					} else {
						mapCanvas.style.cursor = '';
						this.overlay.setPosition(undefined);
						this.featureHover = null;
					}
				},
				{
					layerFilter: (layer) => layer instanceof VectorLayer
				}
			);

			ol2map.getMapInstance().on(
				'click',
				(evt) => {
					ol2map.getMapInstance().forEachFeatureAtPixel(evt.pixel, (feature) => {
						if (feature.getProperties().featureVisible && this.featureHover.featureVisible && feature.getProperties().clickable) {
							this.openPanel(feature.getProperties().id, feature.getProperties());
						}
					});
				},
				{
					layerFilter: (layer) => layer instanceof VectorLayer
				}
			);

			ol2map
				.getMapInstance()
				.getView()
				.on(
					'change:resolution',
					(evt) => {
						var resolution = evt.target.getResolution();
						self.changeFeaturesStyle(resolution);
					},
					{
						layerFilter: (layer) => layer instanceof VectorLayer
					}
				);
		}
	},
	watch: {
		activePanel: 'onActivePanelChange',

		'$store.state.mappanelperiods.activePanelOptions.vistrack': {
			// Este metodo se lanzara cuando se cambie el valor del switch de isActiveTrackPoint
			handler(newVal) {
				this.aisTrackEventPointFeatureCreator.clearTrack();
				this.aisTrackEventVesselLineFeatureCreator.clearTrack();
				if (newVal) {
					const vistrack = this.activePanelOptionsPeriods.vistrack;
					if (!this.activePanelOptionsPeriods.vistrack.isClean) {
						this.drawTrackEventPoints(
							this.aisTrackEventPointFeatureCreator,
							this.aisTrackEventVesselLineFeatureCreator,
							vistrack,
							this.activePanelOptionsPeriods.data.mmsi,
							this.activePanelOptionsPeriods.data.vesselimo
						);
					}
				}
			},
			deep: true
		},

		'$store.state.mappanelperiods.activePanelOptions.seqMovementVis': {
			// Este metodo se lanzara cuando se cambie el valor del switch de isActiveTrackPoint
			handler(newVal) {
				this.movementSequenceFeatureCreator.clearTrack();
				if (newVal) {
					var stop_id = this.activePanelOptionsPeriods.data.id;
					this.drawMovementSequence(this.movementSequenceFeatureCreator, stop_id);
				}
			},
			deep: true
		},

		portInfo(newVal) {
			// Limit extent
			const maxExtent = mapUtils.polygonWktToOlExtent(newVal.bboxextent);
			this.$store.commit('setMapAutorityExtent', maxExtent);

			// Go to port if not multiport
			if (this.portExtensions.length == 1) {
				const fitExtent = mapUtils.polygonWktToOlExtent(newVal.bbox);
				this.$store.commit('setMapAisFitPortExtent', fitExtent);
				this.$store.commit('setMapPeriodsFitPortExtent', fitExtent);
			}

			this.createMap(maxExtent);
		},

		// Watch para saber cuando ha cambiado el estilo del mapa
		'$store.state.mappanelperiods.mapStyleVisualization'(value) {
			this.stopsFeatureCreator.setModeVisualization(value);
			const resolution = ol2map.getMapInstance().getView().getResolution();
			this.changeFeaturesStyle(resolution);
		},

		// Watch para saber cuando ha cambiado searchingtextpastpanel
		'$store.state.mappanelperiods.searchingtextpastpanel'(searchtext) {
			this.stopsFeatureCreator.setSearchingTexVesselName(searchtext);
		},
		// Watch para saber cuando ha cambiado searchingtextfuturepanel
		'$store.state.mappanelperiods.searchingtextfuturepanel'(searchtext) {
			this.stopsFeatureCreator.setSearchingTexVesselName(searchtext);
		},
		// Watch para saber cuando ha cambiado searchingtextrequestedspanel
		'$store.state.mappanelperiods.searchingtextrequestedspanel'(searchtext) {
			this.stopsFeatureCreator.setSearchingTexVesselName(searchtext);
		},
		// Watch para saber cuando ha cambiado searchingtextplanningpanel
		'$store.state.mappanelperiods.searchingtextplanningpanel'(searchtext) {
			this.stopsFeatureCreator.setSearchingTexVesselName(searchtext);
		},
		// Check idstopselected change
		idStopSelected: {
			handler() {
				this.setInstantStopsOption();
			},
			deep: true
		},
		dateChanged: {
			handler() {
				this.setInstantStops();
				this.closeDynamicTabsPanel();
			},
			deep: true
		},
		dateChangedPlanning: {
			handler() {
				// Borramos los datos modificados
				this.$store.commit('setStopsFeaturesInstantPL', []);
				this.$store.commit('setStopsFeaturesInstantModifiedPL', []);
				this.$store.commit('setStopsFeaturesInstantWithModifiedPL', []);
				this.$store.commit('setStopsFeaturesInstantRemovedPL', []);

				this.setInstantStops();
				this.closeDynamicTabsPanel();
			},
			deep: true
		},
		dateChangedAuthorization: {
			handler() {
				// Borramos los datos modificados
				this.$store.commit('setStopsFeaturesInstantAU', []);
				this.$store.commit('setStopsFeaturesInstantModifiedAU', []);
				this.$store.commit('setStopsFeaturesInstantWithModifiedAU', []);
				this.$store.commit('setStopsFeaturesInstantRemovedAU', []);

				this.setInstantStops();
				this.closeDynamicTabsPanel();
			},
			deep: true
		}
	}
};
</script>

<style scoped>
.map-container {
	width: calc(100% + 42px);
	margin-left: -42px;
	height: 100%;
	position: relative;
}
.ol-attribution {
	left: 0px;
}
</style>
